import { notification } from "antd"

let colors = {
  warn: "#fffbe6",
  info: "#e6f7ff",
  success: "#f6ffed",
  error: "#fff1f0"
}

let content = (text, type) => {
  return {
    message: `${text}`,
    description: null,
    placement: 'top',
    style: {
      backgroundColor: colors[type],
      // width: 600,
      fontWeight: 500
    },
    // top: "50"
  }
}

const alerts = {
  warn: (text) => {
    notification.warning(content(text, 'warn'))
  },
  success: (text) => {
    notification.success(content(text, 'success'))
  },
  error: (text) => {
    notification.error(content(text, 'error'))
  },
  info: (text) => {
    notification.info(content(text, 'info'))
  },

}

export default alerts