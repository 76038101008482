import api from 'src/services/api'

const AccountService = {
    getAll: (data) => api.get(`/v1/provider/permission`, data),
    getOne: (id) => api.get(`/v1/provider/permission/${id}`),
    create: (data) => api.post(`/v1/provider/permission`, data),
    update: (id, data) => api.put(`/v1/provider/permission/${id}`, data),
    delete: (id) => api.delete(`/v1/provider/permission/${id}`),
    getModules: () => api.get(`/v1/provider/permission/module`)
}

export default AccountService