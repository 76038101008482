// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import firebaseConfiguration from 'src/firebaseConfiguration.json';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// Initialize Firebase
const app = initializeApp(firebaseConfiguration);
const database = getDatabase(app);

const CollectionTransportLocation = "TransportLocation";

const firebaseHelper = {
  app, 
  database, 
  CollectionTransportLocation
};

export default firebaseHelper;