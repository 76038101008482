import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import { logout } from "../_redux/authCrud";
import { alerts, constants, session } from 'src/utils'

class Logout extends Component {
  componentDidMount() {
    this._logout()
  }

  async _logout () {
    try {

        let response = await logout()

        if( response.status === constants.response.success ){
          session.clear()
          this.props.logout()
        }else{
          alerts.error(response.message);  
        }
        
      } catch (error) {
        alerts.error("Ha ocurrido un error al cerrar la sesión");
      } finally {

      }
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
