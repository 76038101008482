import React, {useEffect, useMemo, useState} from 'react'; 
import useGeoTrackingTransport from './hook';
import {Map, Marker, GoogleApiWrapper, InfoWindow} from 'google-maps-react';
import { utils, mapaUtils } from 'src/utils';
import defaultIconMarker from 'src/assets/images/_icon_location_22.png'

const GeoTrackingTransport = ({ className, toursList, ...props }) => {

  const {
    loading,
    tours,
    initialBoundPoints,
    showInfoWindow,
    activeMarker,
    selectedElementTour,
    onMapReady,
    onInitListTours,
    setSelectedElementTour,
    setActiveMarker,
  } = useGeoTrackingTransport();

  const [mainMap, setMainMap] = useState(null);

  useEffect(() => {
    onInitListTours(toursList)
  }, [toursList]);

  const mapBounds = useMemo(() => {

    if(initialBoundPoints.length == 0) return null;
    let bounds = new props.google.maps.LatLngBounds();
    initialBoundPoints.forEach( boundPoint => {
      bounds.extend({...boundPoint});
    })
    
    return bounds;
  }, [initialBoundPoints]);

  useEffect(() => {

    if(mainMap == null) return;
    
    let zoomChangeBoundsListener = props.google.maps.event.addListener(mainMap, 'bounds_changed', function(event) {
      
      if (this.getZoom() > 14){ //  && this.initialZoom == true

          // Change max/min zoom here
          this.setZoom(14);
          this.initialZoom = false;
      }
    });
    
  	return () => {
      console.log('======> removiendo listener de zoom');
      props.google.maps.event.removeListener(zoomChangeBoundsListener);
    }
  }, [mainMap]);

  const initialPosition = {...mapaUtils.posicionDefault};

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Tours en curso
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            Ubicación geográfica de los transportes con tours en curso
          </span>
        </h3>
        <div className="card-toolbar">
          
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">

        <div className="map-container" style={{height: '550px', position: 'relative'}}>
          <Map
            google={props.google}
            onReady={ (mapProps, map, event) => {
              onMapReady(mapProps, map)
              setMainMap(map);
            }}
            zoom={14}
            style={{
                width: '100%',
                height: '500px',
            }}
            initialCenter={{lat: initialPosition.lat, lng: initialPosition.lng}}
            bounds={mapBounds}
          >

            {
              (tours || []).map((tourItem, index) => {

                if(!tourItem.lat || !tourItem.lng){
                  return null;
                }

                return <Marker
                  key={index}
                  name={`marker_sigem_business_${index}`}
                  description={tourItem.transport_name+" - a"}
                  position={{ lat: tourItem.lat, lng: tourItem.lng }}
                  title={tourItem.transport_name}
                  address={tourItem.transport_name + " - b"}
                  onClick={(props, marker) => {
                    setSelectedElementTour(tourItem);
                    setActiveMarker(marker);
                  }}
                  icon={defaultIconMarker}
                />

              })
            }

            {
              selectedElementTour ? (
                <InfoWindow
                  visible={showInfoWindow}
                  marker={activeMarker}
                  onCloseClick={() => {
                    setSelectedElementTour(null);
                  }}
                >
                  <div>
                    <h4>Tour {selectedElementTour.name || '-'}</h4>
                    <p className="font-weight-bold">{selectedElementTour.transport_name || '-'}</p>
                    <p className="font-weight-bold">{utils.formalStatusGeoTracking(selectedElementTour.geotracking_status || '-')}</p>
                    <p className="font-weight-bold">{selectedElementTour.point_at}</p>
                  </div>
                </InfoWindow>
              ) : null
            }

          </Map>
        </div>
        
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GMAPS_API_KEY,
})(GeoTrackingTransport);