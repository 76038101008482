import api from 'src/services/api'

const AgencyService = {
  getAll: (data) => api.get('/v1/provider/agency', data),
  create: (data) => api.post('/v1/provider/agency', data),
  getOne: (id) => api.get('/v1/provider/agency/'+id),
  update: (id, data) => api.put('/v1/provider/agency/'+id, data),
  delete: (id) => api.delete('/v1/provider/agency/'+id),
}

export default AgencyService;