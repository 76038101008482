import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import routesMap from "../routesMap";

const Account = lazy(() =>import("./modules/Account"));
const AccountStripeConnectOk = lazy(() =>import("./modules/Account/StripeConnectOk"));
const PaypalStripeConnectOk = lazy(() =>import("./modules/Account/PaypalConnectOk"));
const AccountStripeConnectRequestLink = lazy(() =>import("./modules/Account/StripeConnectRequestLink"));


const Users = lazy(() =>import("./modules/Users"));
const Permissions = lazy(() =>import("./modules/Permissions"));
const PermissionForm = lazy(() =>import("./modules/Permissions/form/index"));
const PermissionFormEdit = lazy(() =>import("./modules/Permissions/form/index"));
const Business = lazy(() =>import("./modules/Business"));
const BusinessForm = lazy(() =>import("./modules/Business/form/business-form"));
const BusinessFormEdit = lazy(() =>import("./modules/Business/form/business-form"));

const BusinessTours = lazy(() =>import("./modules/Business/tour-list/business-tour-list"));
const BusinessFormTour = lazy(() =>import("./modules/Business/form-tour/business-form-tour"));

const BusinessFormParking = lazy(() =>import("./modules/Business/form-parking/business-form-parking"));
const BusinessFormRestaurant = lazy(() =>import("./modules/Business/form-restaurant/business-form-restaurant"));
const DiscountCodes = lazy(() =>import("./modules/DiscountCode"));
const Offer = lazy(() =>import("./modules/Offer"));
const TourBooking = lazy(() =>import("./modules/TourBooking"));
const TourBookingForm = lazy(() =>import("./modules/TourBooking/form"));
const TourBookingReSchedule = lazy(() =>import("./modules/TourBooking/reschedule"));
const TourBookingList = lazy(() =>import("./modules/TourBooking/list/tour-booking-list"));
const TourBookingPayment = lazy(() =>import("./modules/TourBooking/payment/tour-booking-payment"));
const Seller = lazy(() =>import("./modules/Seller/index"));
const Agency = lazy(() =>import("./modules/Agency/index"));
const Transport = lazy(() =>import("./modules/Transport/index"));

const TourScheduleGeoTracking = lazy(() =>import("./modules/TourScheduleGeoTracking"));
const SingleTourScheduleGeoTracking = lazy(() =>import("./modules/TourScheduleGeoTracking/single/single-tour-schedule-geotracking"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact={routesMap.dashboard.exact} from="/" to={routesMap.dashboard.path} />
        }
        <ContentRoute path={routesMap.dashboard.path} component={DashboardPage} />
        <ContentRoute path={routesMap.builder.path} component={BuilderPage} />

        <Route exact={routesMap.business.exact} path={routesMap.business.path} name={routesMap.business.name} component={Business} />
        
        <Route exact={routesMap.newBusinessTour.exact} path={routesMap.newBusinessTour.path} name={routesMap.newBusinessTour.name} component={BusinessFormTour}/>
        <Route exact={routesMap.editBusinessTour.exact} path={routesMap.editBusinessTour.path} name={routesMap.editBusinessTour.name} component={BusinessFormTour}/>
        <Route exact={routesMap.businessTours.exact} path={routesMap.businessTours.path} name={routesMap.businessTours.name} component={BusinessTours} />

        <Route exact={routesMap.newBusiness.exact} path={routesMap.newBusiness.path} name={routesMap.newBusiness.name} component={BusinessForm}/>
        <Route exact={routesMap.editBusiness.exact} path={routesMap.editBusiness.path} name={routesMap.editBusiness.name} component={BusinessFormEdit}/>
        <Route exact={routesMap.configBusinessParking.exact} path={routesMap.configBusinessParking.path} name={routesMap.configBusinessParking.name} component={BusinessFormParking}/>
        <Route exact={routesMap.configBusinessRestaurant.exact} path={routesMap.configBusinessRestaurant.path} name={routesMap.configBusinessRestaurant.name} component={BusinessFormRestaurant}/>
        
        <Route exact={routesMap.account.exact} path={routesMap.account.path} name={routesMap.account.name} component={Account} />
        <Route exact={routesMap.accountStripeConnectOk.exact} path={routesMap.accountStripeConnectOk.path} name={routesMap.accountStripeConnectOk.name} component={AccountStripeConnectOk} />
        <Route exact={routesMap.accountPaypalConnectOk.exact} path={routesMap.accountPaypalConnectOk.path} name={routesMap.accountPaypalConnectOk.name} component={PaypalStripeConnectOk} />
        <Route exact={routesMap.accountStripeConnectRequestLink.exact} path={routesMap.accountStripeConnectRequestLink.path} name={routesMap.accountStripeConnectRequestLink.name} component={AccountStripeConnectRequestLink} />

        <Route exact={routesMap.user.exact} path={routesMap.user.path} name={routesMap.user.name} component={Users} />
        <Route exact={routesMap.permission.exact} path={routesMap.permission.path} name={routesMap.permission.name} component={Permissions} />
         <Route exact={routesMap.newPermission.exact} path={routesMap.newPermission.path} name={routesMap.newPermission.name} component={PermissionForm}/>
        <Route exact={routesMap.editPermission.exact} path={routesMap.editPermission.path} name={routesMap.editPermission.name} component={PermissionFormEdit}/>

        <Route exact={routesMap.discountCodes.exact} path={routesMap.discountCodes.path} name={routesMap.discountCodes.name} component={DiscountCodes} />
        <Route exact={routesMap.offers.exact} path={routesMap.offers.path} name={routesMap.offers.name} component={Offer} />
        
        <Route exact={routesMap.tourBooking.exact} path={routesMap.tourBooking.path} name={routesMap.tourBooking.name} component={TourBooking}  />
        <Route exact={routesMap.tourBookingList.exact} path={routesMap.tourBookingList.path} name={routesMap.tourBookingList.name} component={TourBookingList}  />
        <Route exact={routesMap.tourBookingPayment.exact} path={routesMap.tourBookingPayment.path} name={routesMap.tourBookingPayment.name} component={TourBookingPayment}  />
        {/* component={TourBooking} */}
        <Route exact={routesMap.newTourBooking.exact} path={routesMap.newTourBooking.path} name={routesMap.newTourBooking.name} component={TourBookingForm}/>
        <Route exact={routesMap.rescheduleTourBooking.exact} path={routesMap.rescheduleTourBooking.path} name={routesMap.rescheduleTourBooking.name} component={TourBookingReSchedule}/>
        
        <Route exact={routesMap.tourSellers.exact} path={routesMap.tourSellers.path} name={routesMap.tourSellers.name} component={Seller}  />
        <Route exact={routesMap.tourAgencies.exact} path={routesMap.tourAgencies.path} name={routesMap.tourAgencies.name} component={Agency}  />
        <Route exact={routesMap.tourTransports.exact} path={routesMap.tourTransports.path} name={routesMap.tourTransports.name} component={Transport}  />
        <Route exact={routesMap.tourScheduleGeoTrackings.exact} path={routesMap.tourScheduleGeoTrackings.path} name={routesMap.tourScheduleGeoTrackings.name} component={TourScheduleGeoTracking}  />
        <Route exact={routesMap.singleTourSchedGeoTracking.exact} path={routesMap.singleTourSchedGeoTracking.path} name={routesMap.singleTourSchedGeoTracking.name} component={SingleTourScheduleGeoTracking}  />

        <Redirect to={routesMap.dashboard.path} />
      </Switch>
    </Suspense>
  );
}
