import {useEffect, useState} from 'react';
import {alerts, constants} from 'src/utils';
import {TourBookingService} from 'src/services';
import moment from 'moment';

const useNearestTourBooking = () => {
  
  const [key, setKey] = useState("Day");
  const [loading, setLoading] = useState(false);
  const [tourList, setTourList] = useState([]);

  const getTours = async () => {
    try {

      let dataToSend = {
        start_date : moment().startOf(key.toLowerCase()).format('YYYY-MM-DD HH:mm:ss'),
        end_date   : moment().endOf(key.toLowerCase()).format('YYYY-MM-DD HH:mm:ss'),
      }

      let response = await TourBookingService.getResumeTourBookings(dataToSend);

      if(response.status === constants.response.success){
        setTourList(response.data || []);
      }else{
        alerts.warn(response.message);
      }

    } catch (error) {
      alerts.error('Ha ocurrido un error al obtener los tours')
    }
  }

  const getData = async () => {
    setLoading(true);
    await getTours();
    setLoading(false);
  }

  // useEffect(() => {
  //   getData();
  // }, [])

  useEffect(() => {
    getData();
  }, [key])

  return {
    loading,
    key, 
    tourList,
    setKey,
    getTours,
  }

}

export default useNearestTourBooking;