import api from './api'

const SharedService = {
  getCountryList: () => api.get('/v1/provider/shared/countries'),
  getStateList: () => api.get('/v1/provider/shared/states'),
  getMunicipalityList: (data) => api.get('/v1/provider/shared/municipalities', data),
  getLocalityList: (data) => api.get('/v1/provider/shared/localities', data),
  getLanguageList: (data) => api.get('/v1/provider/shared/languages', data),
  getActivityTypeList: () => api.get('/v1/provider/shared/activity-types'),
  getCurrencyList: () => api.get('/v1/provider/shared/currencies'),
  uploadImageFile: (imageFile, folder) => api.post(`/v1/provider/shared/image?folder=${(folder || 'tmp')}`, { file: imageFile }, true),
  getAutoCompleteTourist: (data) => api.get('/v1/provider/shared/autocomplete/tourist', data),
  getSellerList: (params) => api.get('/v1/provider/shared/sellers', params),
  getAgencyList: () => api.get('/v1/provider/shared/agencies'),
  getTransports: (params) => api.get('/v1/provider/shared/transports', params),
}

export default SharedService