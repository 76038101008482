import constants from '../utils/constants';
import session from '../utils/session';
import axios,{ post,put } from 'axios';
import moment from 'moment';
import qs from 'qs'
import {IndexService} from './index'

const baseURL = process.env.REACT_APP_API_URL

const axiosApi = axios.create({
  baseURL: baseURL
});

const getHeaders = () => {
  const dataToReturn = { 'app': 'web_provider', 'devicetype': 'web' };
  const sessionToken = session.get('token')
  if(sessionToken) dataToReturn['Authorization'] = `Bearer ${sessionToken}`

  return dataToReturn
}

const decideFinalProcess = (error, resolve, reject) => {

	let errorParsed = readError(error)

	if(errorParsed && errorParsed.status && errorParsed.status === constants.response.error){
		resolve(errorParsed)
	}else{
		reject(errorParsed)
	}

}

const readError = (error) => {
  console.log(error)
  if (error && error.response && error.response.status !== 401 && error.response.data) {
    let data = error.response.data
    return {
      status: constants.response.error,
      message: data.message,
      errors: data.errors || []
    }
  } else {
    return error
  }
}

axiosApi.interceptors.request.use(function (config) {

  var hh = getHeaders();
  for (var k in hh) {
    if (hh[k] != null) {
      config.headers[k] = hh[k];
    }
  }

  config.paramsSerializer = params => {
    // Qs is already included in the Axios package
    return qs.stringify(params,{
      arrayFormat: "brackets",
      encode: false,
      skipNulls: true,
    });
  };

  return config;
},function (error) {
  return Promise.reject(error);
});

const helperForMultipart = (keys, data, key, i, data2) => {
  
  keys.forEach((key2) => {
    if (data[key][i][key2] && data[key][i][key2] instanceof Date) {
      var strVal = moment(data[key][i][key2]).format('YYYY-MM-DD HH:mm:ss');
      data2.append([key + '[' + i + '][' + key2 + ']'],strVal);
    } else {

      if (Array.isArray(data[key])) {
        data2.append(
          [key + '[' + i + '][' + key2 + ']'],
          JSON.stringify(data[key][i][key2])
        );
      } else {
        data2.append(
          [key + '[' + i + '][' + key2 + ']'],
          data[key][i][key2]
        );
      }
    }
  });
  
  return data2

}

const refreshToken = async () => {
  let _continue = false

  try {
      let dataToSend = {
        token: session.get('refresh_token')
      }
      
    let response = await IndexService.getRefreshToken(dataToSend)

    if(response.status === constants.response.success){
      
      let { token, refresh_token } = response.data

      session.set('token', token)
      session.set('refresh_token', refresh_token)
      _continue = true
    }
  } catch (error) {
    console.log(error)
  } finally {
    return _continue
  }
}

const api = {
  api: axiosApi,
  baseURL: baseURL,
  getHeaders: getHeaders,
  getUrl: (path) => {
    return baseURL + ((path.startsWith('/') || path.startsWith('\\')) ? '' : '/') + path;
  },
  getUrlImage: (file) => {
    let uriFile = ''
    if(file instanceof File){
      uriFile = URL.createObjectURL(file);
    }else{
      uriFile = api.getUrl(file)
    }

    return uriFile
  },
  get: function (path,params) {
    return new Promise((resolve,reject) => {
      axiosApi.get(path,{ params: params || {} })
        .then(resource => {
          resolve(resource ? (resource.data || {}) : {});
        }).catch(error => {
          decideFinalProcess(error, resolve, reject)
          // reject(readError(error));
        });
    });
  },
  post: function (path,data,isMultipart) {
    return new Promise((resolve,reject) => {
      if (data != null && isMultipart) {
        var data2 = new FormData();

        for (var key in data) {
          if (Array.isArray(data[key])) {
            for (var i = 0; i < data[key].length; i++) {
              if (data[key][i] instanceof File || data[key][i] instanceof Blob) {
                data2.append(key,data[key][i]);
              } else {
                
                let keys = Object.keys(data[key][i])

                data2 = helperForMultipart(keys, data, key, i, data2)

              }
            }
          } else {
            if (data[key] && data[key] instanceof Date) {
              var strVal = moment(data[key]).format('YYYY-MM-DD HH:mm:ss');
              data2.append(key,strVal);
            } else {
              data2.append(key,data[key]);
            }
          }
        }
        var config = {
          headers: getHeaders(),
        };

        config.headers['content-type'] = 'multipart/form-data';
        // for (var key of data2.entries()) {
        // 	console.log(key[0] + ', ' + key[1]);
        // }
          axiosApi({
            method: "POST",
            url: baseURL + path,
            data: data2,
            headers: config,
          })
        // post(baseURL + path,data2,config)
          .then(resource => {
            resolve(resource.data || {});
          }).catch(error => {
            decideFinalProcess(error, resolve, reject)
            // reject(readError(error));
          });
      } else {
        axiosApi.post(path,data)
          .then(resource => {
            resolve(resource.data || {});
          }).catch(error => {
            decideFinalProcess(error, resolve, reject)
            // reject(readError(error));
          });
      }
    });
  },
  put: function (path,data,isMultipart) {
    return new Promise((resolve,reject) => {
      if (data != null && isMultipart) {
        var data2 = new FormData();
        for (var key in data) {
          if (Array.isArray(data[key])) {
            for (var i = 0; i < data[key].length; i++) {
              if (data[key][i] instanceof File || data[key][i] instanceof Blob) {
                data2.append(key,data[key][i]);
              } else {

                const keys = Object.keys(data[key][i])

                data2 = helperForMultipart(keys, data, key, i, data2)

              }
            }
          } else {
            if (data[key] && data[key] instanceof Date) {
              var strVal = moment(data[key]).format('YYYY-MM-DD HH:mm:ss');
              data2.append(key,strVal);
            } else {
              data2.append(key,data[key]);
            }
          }
        }

        var config = {
          headers: getHeaders(),
        };

        config.headers['content-type'] = 'multipart/form-data';
          axiosApi({
            method: "PUT",
            url: baseURL + path,
            data: data2,
            headers: config,
          })
        // put(baseURL + path,data2,config)
          .then(resource => {
            resolve(resource.data || {});
          }).catch(error => {
            decideFinalProcess(error, resolve, reject)
            // reject(readError(error));
          });
      } else {
        axiosApi.put(path,data)
          .then(resource => {
            resolve(resource.data || {});
          }).catch(error => {
            decideFinalProcess(error, resolve, reject)
            // reject(readError(error));
          });
      }
    });
  },
  delete: function (path,params) {
    return new Promise((resolve,reject) => {
      axiosApi.delete(path,params ? { data: params } : undefined)
        .then(resource => {
          resolve(resource.data || {});
        }).catch(error => {
          decideFinalProcess(error, resolve, reject)
          // reject(readError(error));
        });
    })
  },
  addResponseInterceptor: (onResponse,onError) => {
    let onResponseCB = onResponse ? onResponse : (response) => { return response }
    let onErrorCB = onError ? onError : (errror) => { return Promise.reject(errror) }
    axiosApi.interceptors.response.use(onResponseCB,onErrorCB)
  },
  removeResponseInterceptor: (id) => {
    axiosApi.interceptors.response.eject(id)
  },
  refreshToken
}

export default api