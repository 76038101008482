import { IndexService, SessionService } from 'src/services'

export function login(username, password) {
  return IndexService.login(username, password)
}

export function logout() {
  return IndexService.logout()
}

export function getUserByToken() {
  return SessionService.getSession()
}
