import api from 'src/services/api'

const SellerService = {
  getAll: (data) => api.get('/v1/provider/seller', data),
  create: (data) => api.post('/v1/provider/seller', data),
  getOne: (id) => api.get('/v1/provider/seller/'+id),
  update: (id, data) => api.put('/v1/provider/seller/'+id, data),
  delete: (id) => api.delete('/v1/provider/seller/'+id),
}

export default SellerService;