import React from 'react'
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types'

const SearchFilterAntD = ({ dataIndex, setSelectedKeys, selectedKeys, confirm, clearFilters, onSearchInput }) => {
  // handleSearch(selectedKeys, confirm, dataIndex)
  // handleReset(clearFilters)
  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          onSearchInput(node)
          // this.searchInput = node;
        }}
        placeholder={`Buscar ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Buscar
        </Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
          Reiniciar
        </Button>
        {/* <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
        >
          Filter
        </Button> */}
      </Space>
    </div>
  )
}

SearchFilterAntD.propTypes = {
  dataIndex       : PropTypes.string,
  setSelectedKeys : PropTypes.func, 
  selectedKeys    : PropTypes.array, 
  confirm         : PropTypes.func, 
  clearFilters    : PropTypes.func,
  // handleSearch    : PropTypes.func,
  // handleReset     : PropTypes.func,
  onSearchInput   : PropTypes.func,
}

export default SearchFilterAntD