/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import routesMap from '../../../../../routesMap'
import PermissionWrapper from 'src/app/components/utils/permission-wrapper'
import { useSelector } from 'react-redux'
import {FallOutlined} from '@ant-design/icons'
import { SessionService } from 'src/services'

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  
  const updateMenuTours = useSelector((state) => state.refreshTours)

  const [toursList, setTourList] = useState([])
  
  const getListTours = async () => {
    try {
      let response = await SessionService.getSession()
      if(response.status === 'OK'){
        setTourList(response?.data?.tours||[])
      }
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getListTours()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMenuTours])
  

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}

        {/* dashboard */}
        <li
          className={`menu-item ${getMenuItemActive(routesMap.dashboard.path, false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={routesMap.dashboard.path}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">{routesMap.dashboard.name}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        <PermissionWrapper modules={['mdu_user', 'mdu_permission_user']}>
          <li
            className={`menu-item menu-item-submenu ${ getMenuItemActive(routesMap.user.path, true)} }`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/user">
              <span className="menu-icon">
                <span className="fa fa-users"></span>
              </span>
              <span className="menu-text">Usuarios</span>
              <i className="menu-arrow" />
            </NavLink>

            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Usuarios</span>
                  </span>
                </li>

                <PermissionWrapper module="mdu_user">
                  <li className={`menu-item ${getMenuItemActive(routesMap.user.path)}`} aria-haspopup="true">
                    <NavLink className="menu-link" to={routesMap.user.path}>
                      <i className="menu-bullet menu-bullet-dot"> <span /> </i>
                      <span className="menu-text">{routesMap.user.name}</span>
                    </NavLink>
                  </li>
                </PermissionWrapper>
                
                <PermissionWrapper module="mdu_permission_user">
                  <li className={`menu-item ${getMenuItemActive(routesMap.permission.path)}`} aria-haspopup="true">
                    <NavLink className="menu-link" to={routesMap.permission.path}>
                      <i className="menu-bullet menu-bullet-dot"> <span /> </i>
                      <span className="menu-text">{routesMap.permission.name}</span>
                    </NavLink>
                  </li>
                </PermissionWrapper>
              </ul>
            </div>

          </li>
        </PermissionWrapper>
        {/* SECCION CUENTAS */}

        
        <PermissionWrapper modules={['mdu_business_crud', 'mdu_business_config']}>
          <li
            className={`menu-item ${getMenuItemActive(routesMap.business.path, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={routesMap.business.path}>
              <span className="svg-icon menu-icon">
                <span className="fa fa-building"></span>
              </span>
              <span className="menu-text">{routesMap.business.name}</span>
            </NavLink>
          </li>
        </PermissionWrapper>

        <PermissionWrapper modules={['mdu_business_config']} forceRender={toursList.length > 0 ? true : false}>
          <li className="menu-section ">
            <h4 className="menu-text">Tours</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          {
            toursList.filter(item => item.enabled ).map((item) => {
              
              return <li key={item.id} className={`menu-item ${getMenuItemActive(routesMap.tourBooking.path.replace(':id', item.id))}`} aria-haspopup="true">
                <NavLink className="menu-link" to={routesMap.tourBooking.path.replace(':id', item.id)}>
                  <i className="menu-bullet menu-bullet-dot"> <span /> </i>
                  <span className="menu-text">{item.name}</span>
                </NavLink>
              </li>
            })
          }

          {
            toursList.length > 0 &&
            <>
              <li
                className={`menu-item ${getMenuItemActive(routesMap.tourBookingList.path, false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={routesMap.tourBookingList.path}>
                  <span className="svg-icon menu-icon">
                    <span className="fa fa-list"></span>
                  </span>
                  <span className="menu-text">{routesMap.tourBookingList.name}</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(routesMap.tourBookingPayment.path, false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={routesMap.tourBookingPayment.path}>
                  <span className="svg-icon menu-icon">
                    <span className="fa fa-dollar-sign"></span>
                  </span>
                  <span className="menu-text">{routesMap.tourBookingPayment.name}</span>
                </NavLink>
              </li>
            </>
          }

          <PermissionWrapper modules={['mdu_business_config']}>
            <li
              className={`menu-item menu-item-submenu`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span className="menu-link menu-toggle">
                <span className="menu-icon">
                  {/* <span className="fa fa-users"></span> */}
                  <FallOutlined />
                </span>
                <span className="menu-text">Descuentos</span>
                <i className="menu-arrow" />
              </span>

              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                      <span className="menu-text">Descuentos</span>
                    </span>
                  </li>

                  <li className={`menu-item ${getMenuItemActive(routesMap.discountCodes.path)}`} aria-haspopup="true">
                    <NavLink className="menu-link" to={routesMap.discountCodes.path}>
                      <i className="menu-bullet menu-bullet-dot"> <span /> </i>
                      <span className="menu-text">{routesMap.discountCodes.name}</span>
                    </NavLink>
                  </li>

                  <li className={`menu-item ${getMenuItemActive(routesMap.offers.path)}`} aria-haspopup="true">
                    <NavLink className="menu-link" to={routesMap.offers.path}>
                      <i className="menu-bullet menu-bullet-dot"> <span /> </i>
                      <span className="menu-text">{routesMap.offers.name}</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          </PermissionWrapper>

          <PermissionWrapper modules={['mdu_business_config']}>
            <li
              className={`menu-item ${getMenuItemActive(routesMap.tourScheduleGeoTrackings.path, false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={routesMap.tourScheduleGeoTrackings.path}>
                <span className="svg-icon menu-icon">
                  <span className="fas fa-map-marked-alt"></span>
                </span>
                <span className="menu-text">{routesMap.tourScheduleGeoTrackings.name}</span>
              </NavLink>
            </li>
          </PermissionWrapper>
          
          <PermissionWrapper modules={['mdu_business_config']}>
            <li
              className={`menu-item ${getMenuItemActive(routesMap.tourSellers.path, false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={routesMap.tourSellers.path}>
                <span className="svg-icon menu-icon">
                  <span className="fas fa-portrait"></span>
                </span>
                <span className="menu-text">{routesMap.tourSellers.name}</span>
              </NavLink>
            </li>
          </PermissionWrapper>
          
          <PermissionWrapper modules={['mdu_business_config']}>
            <li
              className={`menu-item ${getMenuItemActive(routesMap.tourAgencies.path, false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={routesMap.tourAgencies.path}>
                <span className="svg-icon menu-icon">
                  <span className="fas fa-list"></span>
                </span>
                <span className="menu-text">{routesMap.tourAgencies.name}</span>
              </NavLink>
            </li>
          </PermissionWrapper>
          
          <PermissionWrapper modules={['mdu_business_config']}>
            <li
              className={`menu-item ${getMenuItemActive(routesMap.tourTransports.path, false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={routesMap.tourTransports.path}>
                <span className="svg-icon menu-icon">
                  <span className="fas fa-ship"></span>
                </span>
                <span className="menu-text">{routesMap.tourTransports.name}</span>
              </NavLink>
            </li>
          </PermissionWrapper>

        </PermissionWrapper>

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
