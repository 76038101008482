import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const checkHasModule = (mod, modules, create, edit, canDelete) => {

  const esArray = !(typeof mod == 'string');
  
  const found = (modules || []).find((item) => ((!esArray && item.identifier === mod)  || (esArray && mod.indexOf(item.identifier) !== -1)));

  if (found) {
      if (create || edit || canDelete) {
          if ((create && found.create) ||
              (edit && found.edit) ||
              (canDelete && found.delete)
          ) { return true; }
      } else { return true; }
  }
  return false;
};

const PermissionWrapper = ({ module, modules, fallback, ...props}) => {

  let show = false
  const { user } = useSelector((state) => state.auth);

  const permissionModules = user && user.modules ? (user.modules || []) : []

  if(module || modules){

    show = checkHasModule(module || modules, permissionModules, props.create, props.edit, props.delete)

  }

  show = show || props.forceRender
  
  return (
    show ? props.children : ( fallback ? fallback : null)
  );
};

const ValidateHasModule = ({module, requiresCreate, requiresEdit, requiresDelete}) => {

  const { user } = useSelector((state) => state.auth);
  const permissionModules = user && user.modules ? (user.modules || []) : []
  let isOk = false
  if(module){
    isOk = checkHasModule(module, permissionModules, requiresCreate, requiresEdit, requiresDelete)
  }

  return isOk;
}

PermissionWrapper.propTypes = {
  module: PropTypes.string,
  modules: PropTypes.array,
  create: PropTypes.bool,
  edit: PropTypes.bool,
  delete: PropTypes.bool,
  fallback : PropTypes.any,
  children : PropTypes.any,
  forceRender: PropTypes.bool,
}

export { ValidateHasModule };

export default PermissionWrapper;