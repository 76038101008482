import api from 'src/services/api'

const TourBookingService = {
  getTourConfig         : (id, data) => api.get(`/v1/provider/tour/${id}`, data),
  getOfferList          : (id, data) => api.get(`/v1/provider/tour/${id}/offers`, data),
  getDiscountCodeList   : (id, data) => api.get(`/v1/provider/tour/${id}/discount-codes`, data),
  getSchedulesList      : (id, params) => api.get(`/v1/provider/tour/${id}/schedules`, params),
  getTotalPrice         : (id, data) => api.post(`/v1/provider/tour/${id}/validate-booking`, data),
  
  getCanceledDates      : (id, data) => api.get(`/v1/provider/tour/${id}/canceled-date`, data),
  createCanceledDate    : (id, data) => api.post(`/v1/provider/tour/${id}/canceled-date`, data),
  deleteCanceledDate    : (id, idcd, data) => api.delete(`/v1/provider/tour/${id}/canceled-date/${idcd}`, data),
  
  getTourBookings       : (id, data) => api.get(`/v1/provider/tour/${id}/booking`, data),
  createTourBooking     : (id, data) => api.post(`/v1/provider/tour/${id}/booking`, data),
  
  updateTourBooking     : (idtb, data) => api.put(`/v1/provider/tour/bookings/${idtb}/reschedule`, data),
  getAllBookings        : (data) => api.get(`/v1/provider/tour/bookings`, data),
  getOneBooking         : (idtb, data) =>  api.get(`/v1/provider/tour/bookings/${idtb}`, data),
  getRefundBooking      : (idtb, data) =>  api.get(`/v1/provider/tour/bookings/${idtb}/refund`, data),
  cancelBooking         : (idtb, data) =>  api.put(`/v1/provider/tour/bookings/${idtb}/cancel`, data),
  completeCancelBooking : (idtb, data) =>  api.put(`/v1/provider/tour/bookings/${idtb}/complete-pending-cancelation`, data),
  getTourBookingFormat  : (idtb, data) =>  api.get(`/v1/provider/tour/bookings/${idtb}/format`, data),
  getAllPayments        : (data) => api.get(`/v1/provider/tour/payments`, data),
  getAllCommissions        : (data) => api.get(`/v1/provider/tour/commissions`, data),
  activatePendingBooking: (idtb, data) => api.put(`/v1/provider/tour/bookings/${idtb}/activate-pending`, data),

  getResumeTourBookings : (data) => api.get('/v1/provider/tour/resume-bookings', data),
  getResumeTourTopSellers : (data) => api.get('/v1/provider/tour/resume-top-sellers', data),
  getResumeTourPayments : (data) => api.get('/v1/provider/tour/resume-payments', data),
  getBookingsTracking : (data) => api.get('/v1/provider/tour/bookings-tracking', data),


}

export default TourBookingService