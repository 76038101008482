import React from 'react';
import {Row, Col, Card} from 'antd';
import {utils} from 'src/utils';

const containerStyle = {
  height: '100vh',
  backgroundColor: '#b7b7b7',
  width: '100%',

};

const PaymentTourSuccessPage = (props) => {

  const ticket = utils.getQuery(props, 'ticket');
  // const stripeId = utils.getQuery(props, 'session_id');

  return (
    <div style={containerStyle}>
      <div className="container">
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={{offset: 6, span: 12}}>
            <Card title={'Pago realizado'} hoverable style={{marginTop: '50px'}}>
              <div className="text-center">
                <span className="fa fa-check-circle fa-2x text-success"></span>
                <h4>Pago exitoso de reserva de tour</h4>
                <p><strong>Ticket reserva: </strong>{ticket}</p>
                {/* <p><strong>Número de pago: </strong>{stripeId}</p> */}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PaymentTourSuccessPage;