/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import PaymentTourSuccessPage from 'src/app/pages/PaymentTourSuccessPage';
import PaymentTourCanceledPage from 'src/app/pages/PaymentTourCanceledPage';
import PaymentTourFinishedPage from 'src/app/pages/PaymentTourFinishedPage';

import PaymentPaypalTourSuccessPage from 'src/app/pages/PaymentPaypalTourSuccessPage';
import PaymentPaypalTourCanceledPage from 'src/app/pages/PaymentPaypalTourCanceledPage';

import routesMap from "src/routesMap";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Switch>
      
      <Route exact path={routesMap.paymentTourSuccessStripe.path} component={PaymentTourSuccessPage} />
      <Route exact path={routesMap.paymentTourCanceledStripe.path} component={PaymentTourCanceledPage} />
      <Route exact path={routesMap.paymentTourFinishedStripe.path} component={PaymentTourFinishedPage} />
      <Route exact path={routesMap.paymentPaypalTourSuccessStripe.path} component={PaymentPaypalTourSuccessPage} />
      <Route exact path={routesMap.paymentPaypalTourCanceledStripe.path} component={PaymentPaypalTourCanceledPage} />

      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
