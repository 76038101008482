import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "./authRedux";
import { getUserByToken } from "./authCrud";
import {api} from 'src/services'
import {session, alerts} from 'src/utils'
import {useHistory} from 'react-router-dom'
import routesMap from 'src/routesMap'

const axios = require('axios');

let axiosInterceptorRequestId = null

function AuthInit(props) {
  const history = useHistory()
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data: user } = await getUserByToken();
          dispatch(props.fulfillUser(user));
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    axiosInterceptorRequestId = api.addResponseInterceptor(null, async (error) => {
        axios.interceptors.response.eject(axiosInterceptorRequestId);
        let originalRequest = error.config;

        if(error && error.response && error.response.status === 401 && !originalRequest._retry){
            originalRequest._retry = true;

            let _continue = await api.refreshToken()

            if(_continue){
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + session.get('token')
              return api.api(originalRequest);
            }

            let data = error.response.data
            console.log("====> error 401 detectado, forzando cierre de sesión")
            session.clear()
            // session.setFlashValue('error_logout_message', data && data.meta ? data.meta.message : 'Login again');
            let message = data && data.meta ? data.meta.message : 'Login again'
            alerts.error(message)
            history.replace(routesMap.logout.path)
        }else{
            return Promise.reject(error)
        }
    })

    if (authToken) {
      requestUser();
    } else {
      dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);
    }
  
    return () => {
      api.removeResponseInterceptor( axiosInterceptorRequestId )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);
