import api from 'src/services/api'

const TransportService = {
  getAll: (data) => api.get('/v1/provider/transport', data),
  create: (data) => api.post('/v1/provider/transport', data),
  getOne: (id) => api.get('/v1/provider/transport/'+id),
  update: (id, data) => api.put('/v1/provider/transport/'+id, data),
  delete: (id) => api.delete('/v1/provider/transport/'+id),
  updateEnabled: (id) => api.put('/v1/provider/transport/'+id+'/enable'),
}

export default TransportService;