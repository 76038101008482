const constants = {
  authLabels: {
    name: 'SIGEM',
    description: 'Sistema de Gestión Empresarial'
  },
  api_url : process.env.REACT_APP_BASE_URL,
  response: {
      error: 'ERROR',
      unknown_error: 'UNKNOWN_ERROR',
      success: 'OK',
  },
  business_type: {
    tour: {
      name: 'Tour',
      identifier: 'tour'
    },
    restaurant: {
      name: 'Restaurante',
      identifier: 'restaurant'
    },
    bar: {
      name: 'Bar',
      identifier: 'bar'
    },
    parking: {
      name: 'Estacionamiento',
      identifier: 'parking'
    },
  },
  transport_types: {
    boat: {
      name: 'Embarcación',
      identifier: 'boat'
    },
    bus: {
      name: 'Autobus',
      identifier: 'bus'
    },
  },
  months: [
    { id: 'JANUARY', label: 'Enero'},
    { id: 'FEBRUARY', label: 'Febrero'},
    { id: 'MARCH', label: 'Marzo'},
    { id: 'APRIL', label: 'Abril'},
    { id: 'MAY', label: 'Mayo'},
    { id: 'JUNE', label: 'Junio'},
    { id: 'JULY', label: 'Julio'},
    { id: 'AUGUST', label: 'Agosto'},
    { id: 'SEPTEMBER', label: 'Septiembre'},
    { id: 'OCTOBER', label: 'Octubre'},
    { id: 'NOVEMBER', label: 'Noviembre'},
    { id: 'DECEMBER', label: 'Diciembre'},
  ],
  booking_conditions: [
    { id: 'NO_RESTRICTIONS', label: 'Sin restricción' },
    { id: 'ONE_DAY_BEFORE', label: '1 día antes' },
    { id: 'TWO_DAY_BEFORE', label: '2 días antes' },
    { id: 'THREE_DAY_BEFORE', label: '3 días antes' },
    { id: 'ONE_WEEK_BEFORE', label: '1 semana antes'},
  ],
  booking_windows: [
    {id: 'BW_90_DAY', label: '90 días (3 meses)'},
    {id: 'BW_180_DAY', label: '180 días (6 meses)'},
    {id: 'BW_270_DAY', label: '270 días (9 meses)'},
    {id: 'BW_365_DAY', label: '365 días (un año)'},
    {id: 'BW_550_DAY', label: '550 días (18 meses aprox)'},
    {id: 'BW_720_DAY', label: '720 días (2 años aprox)'},
  ],
  business_user_rol: [
    {id: 'tour_coo', name: 'Gerente de operaciones'},
    {id: 'tour_receptionist', name: 'Recepcionista'},
    {id: 'tour_captain', name: 'Capitán'},
    {id: 'tour_seller', name: 'Vendedor de oficina'}
  ],
  extra_service_types: {
    food_drink: {identifier: 'food_drink', name: 'Alimentos y Bebidas'},
    product_article: {identifier: 'product_article', name: 'Productos y Artículos'}, 
    service: {identifier: 'service', name: 'Servicios generales'}
  },
  included_service_types: {
    food_drink: {identifier: 'food_drink', name: 'Alimentos y Bebidas'},
    product_article: {identifier: 'product_article', name: 'Productos y Artículos'}, 
    service: {identifier: 'service', name: 'Servicios generales'}
  },
  ageRangeLabels: {
    'lvl0_2': 'Infantes',
    'lvl3_12': 'Niños',
    'lvl13_17': 'Jóvenes',
    'lvl18': 'Adultos'
    // 'lvl0_2': 'De 0 a 2 años',
    // 'lvl3_12': 'De 3 a 12 años',
    // 'lvl13_17': 'De 13 a 17 años',
    // 'lvl18': 'De 18 en adelante'
  }
}

export default constants;