import {useEffect, useState} from 'react';
import { mapaUtils } from 'src/utils';
import firebaseHelper from 'src/utils/firebaseHelper';
import { ref, onValue, off, query, startAt, limitToFirst, orderByChild, orderByKey} from "firebase/database";
import { useSelector } from "react-redux";
import moment from 'moment';

const useGeoTrackingTransport = () => {

  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [tours, setTours] = useState([]);
  const [initialBoundPoints, setInitialBoundPoints] = useState([]);
  const [toursAssigned, setToursAssigned] = useState(false);
  
  const [selectedElementTour, setSelectedElementTour] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);

  const accountId = user && user.account && user.account.id ? user.account.id : null;
  
  const onMapReady = (mapProps, map) => {
    
  }

  const onInitListTours = async (originalTourList) => {
    setTours(originalTourList || []);
    setToursAssigned(true);
  }

  useEffect(() => {

    // in case account is null, then ommit this state
    if(accountId == null || !toursAssigned) return;

    const transportRef = query(
      ref(firebaseHelper.database, `${firebaseHelper.CollectionTransportLocation}/`),
      orderByKey(),
      startAt(`Account${accountId}Transport`),
      limitToFirst(100),
    );
    
    const _addListenerLocation = async () => {
      
      onValue(transportRef, (snapshot) => {

        if(tours.length > 0){

          const dataObjects = snapshot.val();
          
          let newTourLists = [...tours];
  
          Object.keys(dataObjects).forEach((key, index) => {
  
            const data = dataObjects[key];
      
            let tourFoundIndex = newTourLists.findIndex( tourObj => data.tourIds && (data.tourIds || []).includes(parseInt(tourObj.id)) );
  
            if(tourFoundIndex !== -1){

              let currentDate = moment().subtract(2, 'hour')
              let pointDate = moment(data.pointAt)

              let addPoint = true

              if(data.tourGeoTrackingStatus === 'finalized' && pointDate.isSameOrBefore(currentDate)){
                addPoint = false                              
              }

              if( addPoint ){
                let newTourData = {
                  ...newTourLists[tourFoundIndex],
                  lat: data.latitude,
                  lng: data.longitude,
                  geotracking_status: data.tourGeoTrackingStatus,
                  tour_ids: data.tourIds,
                  tour_recurrence_schedule_ids: data.tourRecurrenceScheduleIds,
                  transport_id: data.transportId,
                  transport_name: data.transportName,
                  point_at: data.pointAt ? moment(data.pointAt, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY hh:mm a") : "-",
                }
        
                newTourLists[tourFoundIndex] = newTourData;
              }

            }
            
          })
  
          setTours(newTourLists);

        }
        
      });

    }

    const _removeListenerLocation = async () => {
      console.log('===========> removing transport location listener');
      off(transportRef);
    }

    _addListenerLocation();
    
  	return () => {
      _removeListenerLocation();
    }
  }, [accountId, toursAssigned]);

  useEffect(()=> {

    if(initialBoundPoints.length == 0){
      let points = tours
        .filter(tourItem => tourItem.lat != null && tourItem.lng != null)
        .map(tourItem => ({lat : tourItem.lat, lng: tourItem.lng}));
        
      setInitialBoundPoints(points);
    }

  }, [tours]);

  return {
    loading,
    tours,
    initialBoundPoints,
    onMapReady,
    onInitListTours,
    selectedElementTour,
    setSelectedElementTour,
    activeMarker,
    setActiveMarker,
    showInfoWindow,
    setInfoWindowFlag,
  };

}

export default useGeoTrackingTransport;