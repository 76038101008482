import api from 'src/services/api'

const AccountService = {
    getPermissionsList: (id) => api.get('/v1/provider/user/permissions'),
    getAllUsers: (data) => api.get(`/v1/provider/user`, data),
    createUser: (data) => api.post(`/v1/provider/user`, data),
    updateUSer: (id, data) => api.put(`/v1/provider/user/${id}`, data),
    deleteUser: (id) => api.delete(`/v1/provider/user/${id}`),
    enabledUser: (id) => api.delete(`/v1/provider/user/${id}/enabled`),
}

export default AccountService