import React from 'react';
import {CircularProgress} from "@material-ui/core";

const InlineLoader = ({style, message, messageStyle, ...props}) => {
  return (
    <div className='text-center' style={style ? style : {}}>
      <CircularProgress className="splash-screen-spinner" />
      {
        message ?
        <div style={ messageStyle ? messageStyle : {fontWeight: 'bold', fontSize: '1.2rem'}}>{message}</div>
        : null
      }
    </div>
  );
};

export default InlineLoader;