
const initialState = {
  lastRefresh: null
};

const refreshTours = (state = initialState, action) =>  {
  const newState = {...state};
  if(action.type === 'REFRESH_TOURS'){
    console.log("====> calling to refresh tours")
    newState.lastRefresh = new Date()
  }

  return newState;
};

export default refreshTours;