import React from 'react'
import SearchFilterAntD from "src/app/components/table/search-filter-antd"
import { SearchOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import moment from 'moment'
import { session } from '.';
import {useLocation} from 'react-router-dom'

const utils = {
  getColumnSearchProps: (dataIndex, filters) => {

    var searchInput = null

    return ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilterAntD
          onSearchInput={ node => searchInput = node }
          dataIndex={dataIndex}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, paddingLeft: '12px', paddingRight: '12px' }} />,
      onFilter: (value, record) => true,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      // render: text => `- ${text} -`
      // text => filters && filters[dataIndex] == text ? (
      //   <Highlighter
      //     highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      //     searchWords={[ filters[dataIndex] ]}
      //     autoEscape
      //     textToHighlight={text ? text.toString() : ''}
      //   />
      // ) : (
      //   text
      // ),

    })
  },
  firstValueOnObjectWithArray: (obj, omitItems) => {
    if(obj){
      for(let k in obj){
        if(obj.hasOwnProperty(k)){
          if( Array.isArray(obj[k])){
            if(omitItems && omitItems.length > 0){
              if( !omitItems.includes(k)){
                obj[k] = obj[k][0] || undefined
              }
            }else{
              obj[k] = obj[k][0] || undefined
            }
          }
        }
      }
    }

    return obj
  },
  formatDate: (date, includeTime) => {
    if(date){
      const md = moment(date)
      if(md.isValid()) return md.format(includeTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')
    }

    return ""
  },
  formatDuration: (durationHHMMSS, includeSeconds) => {
    let mDuration = moment(durationHHMMSS, "HH:mm:ss")

    let parts = [];
    if(mDuration.isValid()){
      let hours = mDuration.hour()
      let minutes = mDuration.minute()
  
      if(hours > 0) parts.push(`${hours} hora${(hours != 1 ? 's' : '')}`);
      if(minutes > 0) parts.push(`${minutes} minuto${(minutes != 1 ? 's' : '')}`);
      
      if(includeSeconds){
        let seconds = mDuration.second()
        if(seconds > 0) parts.push(`${seconds} segundos${(seconds != 1 ? 's' : '')}`);
      }
    }

    return parts.join(', ');

  },
  filterNavMenuWithModules: ( menuList: Array) => {
    
    return menuList.filter( (menuItem, index) => {

      if(menuItem.module) return session.hasModule(menuItem.module)

      if(menuItem.items){
        
        let filteredChildrens = menuItem.items.filter( (subMenuItem, index2) => {
          if(subMenuItem.module) return session.hasModule(subMenuItem.module)
          return true
        })

        if(filteredChildrens.length > 0){
          menuItem.items = filteredChildrens
          return true
        }

      }

      return true

    })

  },
  getParam: function(props, name){
    return (props &&  props.match && props.match.params) ? props.match.params[name] : null;
  },
  getQuery: (props, name) => {
    const vv = props.location ? queryString.parse(props.location.search) : null;
    return vv ? vv[name] : '';
  },
  randomString: (l) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    var length = l || 10;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  findFirstRepeatedBasicValue: (list) => {

    if(list && list.length > 1){

      for(var i = 0; i < list.length; i++){

        for(var j = i+1; j < list.length; j++){

          if(list[i] === list[j]){

            return list[i]

          }

        }

      }

    }

    return null

  },
  formatStringtoMoment: (date) => {
    if(date){
      const md = moment(date)
      if(md.isValid()) return md
    }
    
    return ""
  },
  formatNumber: (value, currency) => {
    try{
      if(value == null) value = 0

      let params = {
      	style: 'currency',
      	currency: 'MXN'
      }

      if(!currency){
        params.minimumFractionDigits = 0
        params.maximumFractionDigits = 0
      }

      let formatter = new Intl.NumberFormat('es-MX', params)

      return formatter.format(value)
    }catch(error){
      return (currency ? "$" : "")+value;
    }
  },
  getNumberWeekDays: (list) => {

    let weekIndexDaysName = [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ]

    return (list || []).map( indexDay => weekIndexDaysName[indexDay % 7] )
  },
  formatStringtoTime: (time) => {
    if(time){
      const md = moment(time, 'HH:mm')
      if(md.isValid()) return md.format('hh:mm a')
    }
    
    return ""
  },
  getNumberMonth: (month) => {
    let months = [
      'JANUARY',
      'FEBRUARY',
      'MARCH',
      'APRIL',
      'MAY',
      'JUNE',
      'JULY',
      'AUGUST',
      'SEPTEMBER',
      'OCTOBER',
      'NOVEMBER',
      'DECEMBER',
    ]

    return months.indexOf(month)
  },
  normalizeText:(str) => {
    return str
      ? str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
      : str
  },
  filterOption : (input, option) => {
    return utils.normalizeText(option.children).includes(
      utils.normalizeText(input)
    );
  },
  useQuery: ()  => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  },
  paymentEcommerceLabel: (status) => {
    if(status == 'paypal') return 'PayPal'
    else if(status == 'credit_card') return 'Tarjeta de crédito/débito'
    else if(status == 'conekta') return 'Conekta'
    else if(status == 'stripe') return 'Stripe'
    else if(status == 'cash') return 'Efectivo'
    else if(status == 'transfer') return 'Transferencia'

    return status
  },
  bookingPaymentMethodLabel: (status) => {
    switch(status){
      case 'link':
        return 'Enlace de pago';
      case 'cash':
        return 'Efectivo';
      case 'transfer':
        return 'Transferencia';
      case 'ecommerce':
        return 'Ecommerce';
      default:
        return 'Desconocido';
    };
  },
  bookingPaymentStatusLabel: (status) => {
    const options = {
      'pending': 'Pendiente', 
      'canceled': 'Cancelado', 
      'process': 'En proceso', 
      'payed': 'Pagado', 
      'rejected': 'Rechazado', 
      'inactive': 'Eliminado'
    };

    return options[status] || 'Desconocido';
  },
  bookingCancelationRefundMethodLabel: (status) => {
    switch(status){
      case 'cash': 
        return 'Efectivo';
      case 'transfer': 
        return 'Transferencia';
      case 'pending': 
        return 'Reembolso Pendiente';
      default:
        return (status || '');
    }
  },
  formalStatusGeoTracking: (status) => {
    switch(status){
      case "started":
        return "Tour iniciado";
      case "destiny_reached":
        return "Destino alcanzado";
      case "backing_to_origin":
        return "Regresando a origen";
      case "finalized":
        return "Tour finalizado";
      default:
        return "Tour no iniciado";
    }
  }
}

export default utils