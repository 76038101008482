import api from 'src/services/api'

const AccountService = {
  getPreferences: (data) => api.get('/v1/provider/account', data),
  generateNewTokenAuth: () => api.post('/v1/provider/account/token'),
  updatePreferences: (data) => api.put('/v1/provider/account', data),

  generateLinkConnectStripe: () => api.post('/v1/provider/account/stripe-connect-link'),
  
  generateLinkConnectPaypal : () => api.post('/v1/provider/account/paypal-connect-link'),
  updateConnectionPaypal    : (data) => api.put('/v1/provider/account/paypal-connect', data),
  disconnectPaypal : () => api.put('/v1/provider/account/paypal-disconnect'),
}

export default AccountService