import { constants } from '.';

const filters = {
  business_user_rol: (value) => {
    let parse = constants.business_user_rol.find(item=>item.id === value)

    return parse?parse.name:value
  }
  
  
}

export default filters;