/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import { Nav, Tab } from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "src/_metronic/_helpers";
import useNearestTourBooking from "./hook";
import {utils} from 'src/utils';
import {Spin} from 'antd';
// import InlineLoader from 'src/app/components/InlineLoader'

const NearestTourBooking = ({ toursList, className }) => {
  
  const {
    key,
    setKey,
    loading,
    tourList,
    getTours,
  } = useNearestTourBooking();

  let sumTotalBookings = tourList.reduce((sum, nextTour) => {
    return sum + parseInt(nextTour.count_bookings || 0);
  }, 0.0);

  // useEffect(() => {
  //   getTours();
  // }, [toursList.length])

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Próximas reservas
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            { sumTotalBookings } reservas
          </span>
        </h3>
        <div className="card-toolbar">
          { loading ? <Spin /> : null}
          <Tab.Container defaultActiveKey={key}>
            <Nav
              as="ul"
              onSelect={_key => setKey(_key)}
              className="nav nav-pills nav-pills-sm nav-dark-75"
            >
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Day"
                  disabled={loading}
                  className={`nav-link py-2 px-4 ${
                    key === "Day" ? "active" : ""
                  }`}
                >
                  Hoy
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Week"
                  disabled={loading}
                  className={`nav-link py-2 px-4 ${
                    key === "Week" ? "active" : ""
                  }`}
                >
                  Esta semana
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Month"
                  disabled={loading}
                  className={`nav-link py-2 px-4 ${
                    key === "Month" ? "active" : ""
                  }`}
                >
                  Este mes
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
        <div className="table-responsive">
          <table className="table table-borderless table-vertical-center">
            <thead>
              <tr>
                <th className="p-0" style={{ width: "50px" }} />
                <th className="p-0" style={{ minWidth: "200px" }} />
                <th className="p-0" style={{ minWidth: "100px" }} />
                <th className="p-0" style={{ minWidth: "125px" }} />
                {/* <th className="p-0" style={{ minWidth: "110px" }} /> */}
              </tr>
            </thead>
            <tbody>
              {/* {
                loading ?
                <tr>
                  <td className="pl-0 py-4 text-center" colSpan={5}>
                    <InlineLoader />
                  </td>
                </tr>
                : null
              } */}

              {
                tourList.length === 0 ?
                <tr>
                  <td className="pl-0 py-4" colSpan={4}>
                    <div className="text-center text-muted">
                      Sin reservas!
                      <br />
                      Al parecer no hay reservas para estas fechas o aún no existen tours registrados
                    </div>
                  </td>
                </tr>
                : null
              }

              {
                tourList.map((tourObj, index) => (
                  <tr key={index}>
                    <td className="pl-0 py-4">
                      <div className="symbol symbol-50 symbol-light mr-1">
                        <span className="symbol-label text-success font-weight-bold font-size-h4">
                          { (tourObj.name[0] || '').toUpperCase() }
                          {/* <SVG
                            src={toAbsoluteUrl("/media/svg/misc/006-plurk.svg")}
                            className="h-50 align-self-center"
                          ></SVG> */}
                        </span>
                      </div>
                    </td>
                    <td className="pl-0">
                      <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {tourObj.name}
                      </span>
                    </td>
                    <td className="text-right">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        { tourObj.count_bookings }
                      </span>
                      <span className="text-muted font-weight-bold">Reservas</span>
                    </td>
                    <td className="text-right">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        { `${tourObj.currency_acronym} `+utils.formatNumber(tourObj.total_amount || 0, true) }
                      </span>
                      <span className="text-muted font-weight-bold">Pagado</span>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NearestTourBooking;