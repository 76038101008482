import {useEffect, useState} from 'react';
import { TourBookingService } from 'src/services';
import moment from 'moment';
import { alerts, constants } from 'src/utils';

const useBookingsToday = () => {

  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);

  const getBooking = async () => {
    try {

      let dataToSend = {
        start_date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      }
      
      let response = await TourBookingService.getBookingsTracking(dataToSend)

      if(response.status === constants.response.success){
        setBookings(response.data || [])
      }else{
        alerts.warn(response.message)
      }

    } catch (error) {
      alerts.error('Ha ocurrido un error al obtener las reservas')
    }
  }

  const reloadTable = async () => {
    if(loading) return;
    setLoading(true)
    await getBooking();
    setLoading(false)
  }

  const onComponentMount = async () => {
    setLoading(true)
    await getBooking();
    setLoading(false)
  }

  useEffect(() => {
    onComponentMount();
  }, []);

  return {
    loading,
    bookings,
    reloadTable
  };

}

export default useBookingsToday;