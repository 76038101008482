import React from 'react';
import PropTypes from 'prop-types'

const TableWraperANTD = ({children, className, ...props}) => {
  return (
    <div className={"ant-table-wrapper "+(className || "")}>
      <div className="ant-spin-nested-loading">
        <div className="ant-spin-container">
          <div className="ant-table ant-table-empty">
            <div className="ant-table-container">
              <div className="ant-table-content">
                { children }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TableWraperANTD.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default TableWraperANTD;