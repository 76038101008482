/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useLayoutEffect, useEffect} from "react";
import objectPath from "object-path";
import {useLocation} from "react-router-dom";
// import {QuickActions} from "./components/QuickActions";
import {BreadCrumbs} from "./components/BreadCrumbs";
import {useSubheader} from "../../_core/MetronicSubheader";
import {useHtmlClassService} from "../../_core/MetronicLayout"
import routesMap from "src/routesMap";
import {matchPath} from 'react-router-dom'

var getPaths = function getPaths(pathname) {
  var paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce(function (prev, curr) {
    var currPath = prev + '/' + curr;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

var findRoute = (routes, url, ignoreARoutes) => {
  var aroute = routes
  .filter( route => {
    let exists = ignoreARoutes.find( item => item.path === route.path );
    return exists ? false : true;
  })
  .find(function (route) {
    return matchPath(url, { path: route.path, exact: route.exact });
  });

  return aroute ? { 
    ...aroute,
    title: aroute.name,
    pathname: url,
  } : null; // && aroute.name ? aroute.name : null;
};

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
          uiService.config,
          "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
          "subheader_container",
          true
      )
    };
  }, [uiService]);

  useLayoutEffect(() => {
    // const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    // const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    // const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
    // subheader.setBreadcrumbs(breadcrumbs);
    // subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title);

    let routes = Object.keys(routesMap).map( key => routesMap[key] );
    // console.log("===> routes")
    // console.log(routes)
    // console.log("====> location.pathname")
    // console.log(location.pathname)
    var paths = getPaths(location.pathname);
    
    let itemsNav = [];
    paths.forEach((pathItem, index) => {
      let routeFound = findRoute(routes, pathItem, itemsNav);
      if(routeFound) itemsNav.push(routeFound);
    });
    
    let lastNavName = itemsNav.length > 0 ? itemsNav[itemsNav.length - 1 ].name : '';
    
    subheader.setBreadcrumbs(itemsNav);
    subheader.setTitle(lastNavName);

    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  return (
      <div
          id="kt_subheader"
          className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
      >
        <div
            className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
        >
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-1">
            {layoutProps.subheaderMobileToggle && (
                <button
                    className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                    id="kt_subheader_mobile_toggle"
                >
                  <span/>
                </button>
            )}

            <div className="d-flex align-items-baseline mr-5">
              <h5 className="text-dark font-weight-bold my-2 mr-5">
                <>
                  {subheader.title}
                </>
                {/*<small></small>*/}
              </h5>

            </div>


            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>
          
        </div>
      </div>
  );
}
