/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import InlineLoader from 'src/app/components/InlineLoader'
import { utils, constants, alerts } from "src/utils";
import {NotificationService} from 'src/services';
import {Button} from 'antd';

const DELAY_REQUEST_COUNT_NOTIF_SEC = 5 * 60; // every 5 minutes request notification unseen count

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

var intervalId = null;

export function UserNotificationsDropdown() {
  const [key, setKey] = useState("Events");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const [loading, setLoading] = useState(false);
  
  const prevUnreadNotificationCountRef = useRef();
  const [showNotificationCount, setShowNotificationCount] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [alertData, setAlertData] = useState({
    pageSize: 20,
    count: 0,
    page: 1,
    list: [],
  });
  const [eventData, setEventData] = useState({
    pageSize: 20,
    count: 0,
    page: 1,
    list: [],
  });

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  const disableFlagNewNotifications = async () => {

    setTimeout( async () => {
      if(showNotificationCount){
        setLoading(true);
        try {
          let response = await NotificationService.updateCountSeen()
          if(response.status === constants.response.success){
            setShowNotificationCount(false);
          }else{
            alerts.warn(response.message);
          }
        } catch (error) {
          alerts.error('Ha ocurrido un error al actualizar las notificaciones')
        }
        setLoading(false);
      }
    }, 750);

  }

  const getCountUnseenNotifications = async () => {
    try {
      let response = await NotificationService.getCountUnseen()
      if(response.status === constants.response.success){
        setUnreadNotificationCount(response.data.count || 0);
        setShowNotificationCount(response.data.has_new_notification || false);
      }else{
        alerts.warn(response.message)
      }
    } catch (error) {
      alerts.error('Ha ocurrido un error al obtener las notificaciones')
    }
  }

  const getProviderNotificationsEvents = async (newPage) => {
    try {
      let dataToSend = {
        count : eventData.pageSize,
        page: newPage || eventData.page,
      };

      let response = await NotificationService.getAllEvents(dataToSend);
      if(response.status === constants.response.success){
        setEventData({
          ...eventData,
          page: newPage || eventData.page,
          count: response.data.count,
          list: [
            ...eventData.list,
            ...response.data.rows
          ]
        });
      }else{
        alerts.warn(response.message)
      }
    } catch (error) {
      alerts.error('Ha ocurido un error al obtener las notificaciones')
    }
  }

  const getProviderNotificationsAlerts = async (newPage) => {
    try {
      let dataToSend = {
        count : alertData.pageSize,
        page: newPage || alertData.page,
      };

      let response = await NotificationService.getAllAlerts(dataToSend);
      if(response.status === constants.response.success){
        setEventData({
          ...alertData,
          page: newPage || alertData.page,
          count: response.data.count,
          list: dataToSend.page === 1 ? response.data.rows : [
            ...alertData.list,
            ...response.data.rows
          ]
        });
      }else{
        alerts.warn(response.message)
      }
    } catch (error) {
      alerts.error('Ha ocurido un error al obtener las notificaciones')
    }
  }

  const onComponentMount = async () => {
    setLoading(true);
    await getCountUnseenNotifications();
    setLoading(false);
  }

  const reloadProviderNotifications = async () => {
    setLoading(true);
    await getProviderNotificationsAlerts(1);
    await getProviderNotificationsEvents(1);
    setLoading(false);
  }

  const addNotificationListeners = () => {
    
    intervalId = setInterval(() => {

      getCountUnseenNotifications();

    },DELAY_REQUEST_COUNT_NOTIF_SEC * 1000);
  }

  const removeNotificationListeners = () => {
    if(intervalId != null){
      clearInterval(intervalId);
      intervalId = null;
    }
  }

  useEffect(() => {

    addNotificationListeners();

    onComponentMount();

    return () => {
      removeNotificationListeners();
    }

  }, [])

  useEffect(() => {

    if(prevUnreadNotificationCountRef.current != unreadNotificationCount){
      reloadProviderNotifications();
    }
    
    prevUnreadNotificationCountRef.current = unreadNotificationCount;

  }, [unreadNotificationCount])

  const showBadge = showNotificationCount && unreadNotificationCount > 0;

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className={`btn btn-icon btn-clean btn-lg mr-1 ${ showBadge ? 'pulse pulse-primary' : ''}`}
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight onToggle={(isOpen) => { if(isOpen) disableFlagNewNotifications(); }}>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  Notificaciones
                </Tooltip>
              }
            >
              <div
                className={`btn btn-icon btn-clean btn-lg mr-1 ${ showBadge ? 'pulse pulse-primary' : ''}`}
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                  />
                </span>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">Notificaciones</span>
                  {
                    showBadge ? 
                    <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                      { unreadNotificationCount > 99 ? '+99' : `${unreadNotificationCount}`} nuevos
                    </span>
                    : null
                  }
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}
                  >
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey="Events"
                        className={`nav-link show ${
                          key === "Events" ? "active" : ""
                        }`}
                      >
                        Eventos
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item" as="li">
                      <Nav.Link
                        eventKey="Alerts"
                        className={`nav-link show ${
                          key === "Alerts" ? "active" : ""
                        }`}
                      >
                        Alertas
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="tab-content">
                  <Tab.Pane
                      eventKey="Events"
                      id="topbar_notifications_events"
                    >
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="navi navi-hover scroll my-4"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >
                        { loading ? <InlineLoader message="Obteniendo..." /> : null }
                        {
                          eventData.list.length > 0 ?
                          <>
                          {
                            eventData.list.map((eventObject, index) => {
                              return <a href="#" className="navi-item" key={index} onClick={e => {
                                e.preventDefault();
                              }}>
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-paper-plane text-danger"></i>
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      {/* Finance report has been generated */}
                                      { eventObject.message }
                                    </div>
                                    <div className="text-muted">{ utils.formatDate(eventObject.created_at, true) }</div>
                                    {/* 25 hrs ago */}
                                  </div>
                                </div>
                              </a>
                            })
                          }

                          {
                            eventData.count > eventData.list.length ?
                            <div style={{marginTop: '15px', marginBottom: '10px'}} className="text-center">
                              <Button onClick={e => getProviderNotificationsAlerts(eventData.page + 1) } type="default" icon={<span className="fa fa-arrow-down px-2"></span>}>
                                Obtener más
                              </Button>
                            </div>
                            : <div className="text-info text-center">No hay más resultados!</div>
                          }
                          </>
                          : 
                          <div className="d-flex flex-center text-center text-muted min-h-200px">
                            Sin novedades!
                            <br />
                            No hay nuevos eventos
                          </div>
                        }
                      </PerfectScrollbar>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Alerts" className="p-8">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll pr-7 mr-n7"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >
                        { loading ? <InlineLoader message="Obteniendo..." /> : null }
                        {
                          alertData.list.length > 0 ?
                          <>
                          {
                            alertData.list.map((alertObject, index) => {
                              return <div className="d-flex align-items-center mb-6" key={index}>
                                <div className="symbol symbol-40 symbol-light-warning mr-5">
                                  <span className="symbol-label">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Write.svg"
                                      )}
                                      className="svg-icon-lg svg-icon-warning"
                                    ></SVG>
                                  </span>
                                </div>
                                <div className="d-flex flex-column font-weight-bold">
                                  <a
                                    href="#"
                                    className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                                  >
                                    {alertObject.title}
                                  </a>
                                  <span className="text-muted">
                                    {alertObject.message}
                                    {/* PHP, SQLite, Artisan CLIмм */}
                                  </span>
                                </div>
                              </div>
                            })
                          }

                          {
                            alertData.count > alertData.list.length ?
                            <div style={{marginTop: '15px', marginBottom: '10px'}} className="text-center">
                              <Button onClick={e => getProviderNotificationsAlerts(alertData.page + 1) } type="default" icon={<span className="fa fa-arrow-down px-2"></span>}>
                                Obtener más
                              </Button>
                            </div>
                            : <div className="text-info text-center">No hay más resultados!</div>
                          }
                          </>
                          : 
                          <div className="d-flex flex-center text-center text-muted min-h-200px">
                            Todo bien
                            <br />
                            No hay nuevas alertas
                          </div>
                        }
                      </PerfectScrollbar>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
