import {useState, useEffect} from 'react';
import {alerts, constants} from 'src/utils';
import {TourBookingService} from 'src/services';
import moment from 'moment';

const useTopSellers = () => {

  const [loading, setLoading] = useState(false);
  const [listTopSellers, setListTopSellers] = useState([]);

  const getListTopSellers = async () => {
    try {
      
      let dataToSend = {
        start_date: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      };

      let response = await TourBookingService.getResumeTourTopSellers(dataToSend)

      if(response.status === constants.response.success){
        setListTopSellers(response.data || []);
      }else{
        alerts.warn(response.message)
      }

    } catch (error) {
      alerts.error('Ha ocurrido un error al obtener la lista de vendedores')
    }
  }

  const onComponentMount = async () => {
    setLoading(true)
    await getListTopSellers()
    setLoading(false)
  }

  useEffect(() => {
    onComponentMount();
  }, [])

  return {
    loading,
    listTopSellers
  }

}

export default useTopSellers;