/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import {Dropdown} from "react-bootstrap";
import {toAbsoluteUrl} from "src/_metronic/_helpers";
import {DropdownCustomToggler, DropdownMenu1, DropdownMenu2} from "src/_metronic/_partials/dropdowns";
import useTopSellers from './hook';

const ItemDropdown = ({item}) => {
  return (<>
  <Dropdown className="dropdown-inline" alignRight>
    <Dropdown.Toggle
      id="dropdown-toggle-top"
      as={DropdownCustomToggler}>
      <i className="ki ki-bold-more-hor" />
    </Dropdown.Toggle>
    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
      <DropdownMenu1 />
    </Dropdown.Menu>
  </Dropdown>
  </>);
};

const TopSellers = ({ className }) => {

  const {
    loading,
    listTopSellers,
  } = useTopSellers();

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header border-0 pt-5">

          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Top vendedores del mes
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              Julio
            </span>
          </h3>
          <div className="card-toolbar">
            {/* <Dropdown className="dropdown-inline" drop="down" alignRight>
              <Dropdown.Toggle
                id="dropdown-toggle-top2"
                variant="transparent"
                className="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle">
                Create
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu2 />
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        </div>
        {/* Body */}
        <div className="card-body pt-2">
          {
            listTopSellers.map((topSellerObj, index) => (
              <div className="d-flex align-items-center mb-10" key={index}>
                <div className="symbol symbol-40 symbol-light-success mr-5">
                  <span className="symbol-label">
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/avatars/009-boy-4.svg")}
                    ></SVG>
                  </span>
                </div>
                <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                  <a
                    href="#"
                    className="text-dark text-hover-primary mb-1 font-size-lg"
                  >
                    {topSellerObj.first_name}&nbsp;{topSellerObj.last_name || ''}
                  </a>
                  <span className="text-muted">{topSellerObj.count_bookings || 0} reservas</span>
                </div>
                {/* <ItemDropdown item="" /> */}
              </div>
            ))
          }

          {
            listTopSellers.length === 0 ?
            <div className="d-flex flex-center text-center text-muted min-h-200px">
              Sin estadísticas!
              <br />
              No fue posible obtener a los vendedores de este mes. Es posible que sea por que no existan reservas o tours.
            </div>
            : null
          }
        </div>
      </div>
    </>
  );
}

export default TopSellers;