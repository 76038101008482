import api from 'src/services/api'

const OfferService = {
  getAll: (data) => api.get('/v1/provider/offer', data),
  create: (data) => api.post('/v1/provider/offer', data),
  getOne: (id) => api.get('/v1/provider/offer/'+id),
  update: (id, data) => api.put('/v1/provider/offer/'+id, data),
  delete: (id) => api.delete('/v1/provider/offer/'+id),
  getTourList: () => api.get('/v1/provider/offer/tour-list'),
  getTourRecurrenceList: (data) => api.get('/v1/provider/offer/tour-recurrence-list', data),
  getTourRecurrenceScheduleList: (data) => api.get('/v1/provider/offer/tour-recurrence-schedule-list', data),
}

export default OfferService