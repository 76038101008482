import React, { useState, useEffect } from 'react';
import {Row, Col, Card, Alert} from 'antd';
import {utils, constants} from 'src/utils';
import InlineLoader from '../components/InlineLoader';
import {ClientRESTService} from 'src/services'

const containerStyle = {
  height: '100vh',
  backgroundColor: '#b7b7b7',
  width: '100%',

};

const PaymentTourSuccessPage = (props) => {

  const ticket = utils.getQuery(props, 'ticket');
  const token = utils.getQuery(props, 'token');

  const [loading, setLoading] = useState(false);
  const [successCapture, setSuccessCapture] = useState(false);

  const sendCapturePaypalOrder = async () => {
    setLoading(true)
    try {
      let dataToSend = {
        ticket: ticket,
        orderId: token
      };

      let response = await ClientRESTService.capturePaypalPayment(dataToSend)

      if(response.status === constants.response.success){
        setSuccessCapture(true);
      }else{
        setSuccessCapture(false);
      }

    } catch (error) {
      setSuccessCapture(false);
    }
    setLoading(false)
  }

  useEffect(() => {
    if(ticket && token){
      sendCapturePaypalOrder();
    }else{
      setSuccessCapture(false);
    }
  }, [token])

  return (
    <div style={containerStyle}>
      <div className="container">
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={{offset: 6, span: 12}}>
            <Card title={'Pago realizado'} hoverable style={{marginTop: '50px'}}>
              {
                loading ?
                  <InlineLoader message="Espere un momento por favor..." />
                : (
                  successCapture ?
                  <div className="text-center">
                    <span className="fa fa-check-circle fa-2x text-success"></span>
                    <h4>Pago exitoso de reserva de tour</h4>
                    <p><strong>Ticket reserva: </strong>{ticket}</p>
                    {/* <p><strong>Número de pago: </strong>{stripeId}</p> */}
                  </div>
                  : 
                  <Alert 
                    showIcon
                    message={'Error'}
                    description={`Ha ocurrido un error al finalizar el proceso de pago. Ticket: ${ticket}`}
                    type={'error'}
                  />
                )
              }
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PaymentTourSuccessPage;